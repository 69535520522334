/**
 * Created BY HMSPL 
 * ASC FSC claim Service Class
 * Last Modified By selva on 23/10/2018
 * Last Modified Description : Created UVD master service
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';
// import { Http, Response } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
// import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MasterService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * UVD POST
     * @param {Object} params
     * return Object
     * 
     * Commented By Anil on 25
    */
    post(params,url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.post(resData.url, params,{headers:resData.headers});
    }
     
    // Code Added By Anil on 25/03/2021
    postApiUrl(params,url)
    {
        const resData=RequestService.postApiURLReq(url);
        return this.httpClient.post(resData.url,params,{headers:resData.headers});
    }
 
    /**
     * UVD GET
     * @param {Object} request
     * return Object
     */
    get(param,url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
    }
        /**
     * UVD PUT
     * @param {Object} request
     * return Object
     */
    put(param,url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.put(resData.url,param,{headers:resData.headers});
    }

    delete(url){
        const resData = RequestService.uvdReq(url);
        return this.httpClient.delete(resData.url,{headers:resData.headers});
    }

    importFile(reqData:any,url,category){        
        const options = {
            headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('authToken'))    
        }

        var respData = RequestService.uvdOnPrimReq(url);
        var formData: any = new FormData();
        if(category == 'single'){
            formData.append('file',reqData,reqData['name']);
        }else{
            for(let i=0; i < reqData.length; i++){
                formData.append("file", reqData[i], reqData[i]['name']);
            }
        }
        return this.httpClient.post(respData.url,formData,options);
      }
      getPDF(param,url): any {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers, responseType: 'blob'})
                .map((res:any) => {
                return new Blob([res], { type: 'application/pdf', });
            });
    }
    postPDF(params,url): any {
        const resData=RequestService.postApiURLReq(url);
        return this.httpClient.post(resData.url,params,{headers:resData.headers,responseType: 
            'blob',observe: 'response'});
        // const resData = RequestService.uvdReq(url);
        // return this.httpClient.post(resData.url, params,{headers:resData.headers,responseType: 'blob',observe: 'response'})
        //         .map((res:any) => {
        //         var Name = res.headers.get('content-disposition');
        //         return new Blob([res], { type: 'application/pdf', }, );
        //     });
    }
    getExcel(param,url): any {
        const resData=RequestService.postApiURLReq(url);
        return this.httpClient.post(resData.url,param,{headers:resData.headers,responseType: 
            'blob',observe: 'response'});
            
        // const resData = RequestService.uvdReq(url);
        // return this.httpClient.post(resData.url, param,{headers:resData.headers,responseType: 'blob',observe: 'response' })
        //         .map((res:any) => {
        //             res.fileName = res.headers.get('content-disposition');
        //         return new Blob([res], { type: 'application/vnd.ms-excel', });
        //     });
    }
}