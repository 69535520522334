/**
 * Created BY HMSPL 
 * Request Service Class
 * Last Modified By Balaji on 31/08/2018
 * Last Modified Description : Created request service
 */

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class RequestService {
    constructor() {

    }

    public static baseUrl(): string {
        return environment['uvdHost'];
    }
    public static baseWebApiURL(): string {
        return environment['uvdHost'];
    }
    public static baseUrlUVD(): string {
        return environment['uvdHost'];
    }

    public static baseONPrimUrlUVD(): string {
        return environment['uvdHost'];
    }
    public static baseUrlCentralService(): string {
        return environment['hostCentralService'];
    }

    // public static baseUrl(): string {
    //     return environment['uvdHost'];
    // }
    // public static baseWebApiURL(): string {
    //     return environment['uvdHost'];
    // }
    // public static baseUrlUVD(): string {
    //     return environment['uvdHost'];
    // }
    // public static baseUrlCentralService(): string {
    //     return environment['hostCentralService'];
    // }
    /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static getReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
        return {
            url: this.baseUrl() + url,
           // url: this.baseWebApiURL() + url,
            headers: headers
        };
    }
    static getReq1(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + localStorage.getItem('authToken')});;
        return {
            url: this.baseUrl() + url,
            headers: headers
        };
    }
    /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static loginpostReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return {
            url: this.baseUrl() + url,
            headers: headers
        };
    }

    static postReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authToken') });
        return {
            url: this.baseUrl() + url,
            headers: headers
        };
    }
    /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static loginReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
        return {
            url: this.baseUrl() + url,
            headers: headers
        };
    }
    static loginWebApiReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
        return {
            url: this.baseWebApiURL() + url,
            headers: headers
        };
    }
       /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static tokenReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
        return {
            url: this.baseUrlUVD()+'/' + url,
            headers: headers
        };
    }
    static tokenReqServiceSales(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
        return {
            url: this.baseWebApiURL()+'/' + url,
            headers: headers
        };
    }

    static uvdReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + localStorage.getItem('authToken')});
        return {
            url: this.baseUrlUVD() + url,
            headers: headers
        };
    }

    static uvdOnPrimReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + localStorage.getItem('authToken')});
        return {
            url: this.baseONPrimUrlUVD() + url,
            headers: headers
        };
    }

    static WebApiReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + localStorage.getItem('authToken')});
        return {
            url: this.baseWebApiURL() + url,
            headers: headers
        };
    }
       /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static getApiURLReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'text/plain', 'Authorization': 'Bearer ' + localStorage.getItem('authToken') });
        return {
            url: this.baseWebApiURL() + url,
            headers: headers
        };
    }
    /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static postApiURLReq(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authToken') });
        return {
            url: this.baseWebApiURL() + url,
            headers: headers
        };
    }
    /**
     * Get 
     * @param {string} url
     * Return Object
     */
    static getReqCentralService(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
        return {
            url: this.baseUrlCentralService() + url,
            headers: headers
        };
    }

    static postReqCentralService(url) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return {
            url: this.baseUrlCentralService() + url,
            headers: headers
        };
    }
}
