import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { ThemeService } from 'src/app/api-services/utils/theme-service';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PendingJobcardstatusDO } from 'src/app/api-services/dto/job-card';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { JobCardService } from 'src/app/api-services/job-card.service';
import { HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/api-services/login.service';
// import { Message } from '../../api-services/utils/error-message';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  dealerName: any;
  qAddMenuArr = [
    {
      name: 'newJC',
      state: 'jobcard-create'
    },
    {
      name: 'newJCInvoice',
      state: 'service/jobcardinvoice/job-card-invoice-create'
    },
    {
      name: 'newServiceApoint',
      state: 'service/serviceappointment/service-appointment-create'
    },
    {
      name: 'newServiceRemind',
      state: 'service/servicefollowup/service-reminder-view'
    }
  ]
  lang: any;
  breadCrumb: any;
  isDeskView = true;
  subString: any;
  titles: any;
  public isDealerLogin:any = localStorage.isDealerLogin;
  public isDTCLogin:any = localStorage.isDTCLogin;
  public branchList:any = localStorage.branches;
  public jobcardDetails: Array<PendingJobcardstatusDO> = [];
  @Output() sideToggle = new EventEmitter<any>();
  constructor(private toastr: ToastrService, private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog
    , private jobCardService: JobCardService,private loginService:LoginService) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.getBreadCrumbName();
    }
  }
  getBreadCrumbName() {
    if (this.router) {
      if(this.isDTCLogin == "false"){
        this.dealerName = CommonService.getDealerData().DEALERSHIP_NAME;
      } else if(this.isDTCLogin == "true") {
        const branchId = CommonService.getDealerData().BRANCH_ID;
        var branchName = JSON.parse(this.branchList).find(x => x.BRANCH_ID == branchId).BRANCH_NAME;
        this.dealerName = CommonService.getUserData().LOGIN_ID+ ' - ' + branchName;
      } else {
        this.dealerName = CommonService.getDealerData().DEALERSHIP_NAME;
      }
      this.router.events.filter(e => e instanceof NavigationEnd)
        .map(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        })
        .mergeMap(route => route.data)
        .subscribe(data => {
          this.breadCrumb = this.getTitle(data.title);
          // if (this.breadCrumb.split('/').length > 1) {
          //   document.title = 'DMS - ' + this.breadCrumb.replace(/\//g, ' - ');
          // } else {
          //   document.title = 'DMS - ' + this.breadCrumb;
          // }
        });
    }
  }
  ngOnInit() {
    if (window.innerWidth >= 320 && window.innerWidth <= 480) {
      this.isDeskView = false;
    } else {
      this.isDeskView = true;
    }
    this.qAddMenuArr.filter(key => {
      if (key.name in this.lang.SERVICES) {
        key.name = this.lang.SERVICES[key.name];
      }
    })
  }
  getTitle(title) {
    this.titles = {
      'jobCard': this.lang.MENU.service + ' / ' + this.lang.MENU.jobCard,
      'createJobCard': this.lang.MENU.service + ' / ' + this.lang.MENU.jobCard + ' / ' + this.lang.GENERAL.create,
      'ASCFSCClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.ASCFSCClaim,
      'createASCFSCClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.ASCFSCClaim,
      'ASCWarranty': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.ASCWarrantyClaim,
      'createASCWarranty': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.ASCWarrantyClaim,
      'dealerFSC': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.dealerFSCClaim,
      'createDealerFSC': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.dealerFSCClaim,
      'transitClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.transitClaim,
      'createTransitClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.transitClaim,
      'warrantyClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.warrantyClaim,
      'createWarrantyClaim': this.lang.MENU.service + ' / ' + this.lang.MENU.claims + ' / ' + this.lang.MENU.warrantyClaim + ' / ' + this.lang.GENERAL.create + ' ' + this.lang.MENU.warrantyClaim,
      'crmWelcome': this.lang.MENU.service + ' / ' + this.lang.MENU.CRMWelcomeKit,
      'createCrmWelcome': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.CRMWelcomeKit,
      'jobcardInvoice': this.lang.MENU.service + ' / ' + this.lang.MENU.jobCardInvoice,
      'createJobcardInvoice': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.jobCardInvoice,
      'outworkMemo': this.lang.MENU.service + ' / ' + this.lang.MENU.outworkMemo,
      'createOutworkMemo': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.outworkMemo,
      'outworkPayment': this.lang.MENU.service + ' / ' + this.lang.MENU.outworkPayment,
      'createOutworkPayment': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.outworkPayment,
      'PSF': this.lang.MENU.service + ' / ' + this.lang.MENU.PSF,
      'createPSF': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.PSF,
      'serviceAMC': this.lang.MENU.service + ' / ' + this.lang.MENU.serviceAMC,
      'createServiceAMC': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.serviceAMC,
      'serviceAppoint': this.lang.MENU.service + ' / ' + this.lang.MENU.serviceAppointment,
      'createServiceAppoint': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.serviceAppointment,
      'serviceRemainder': this.lang.MENU.service + ' / ' + this.lang.MENU.serviceRemainder,
      'createServiceRemainder': this.lang.MENU.service + ' / ' + this.lang.GENERAL.create + ' / ' + this.lang.MENU.serviceRemainder,
      'vehicleHistory': this.lang.MENU.service + ' / ' + this.lang.MENU.vehicleServiceHistory,
      'administator': this.lang.MENU.administator,
      'procurement': this.lang.MENU.UVD + ' / ' + this.lang.MENU.procurement,
      'preRefurbInspection': this.lang.MENU.UVD + ' / ' + this.lang.MENU.preRefurbInspection,
      'procurementCreate': this.lang.MENU.UVD + ' / ' + this.lang.MENU.procurementCreate,
      'refurbishmentJobCard': this.lang.MENU.UVD + ' / ' + this.lang.MENU.refurbishment,
      'refurbishmentFinalChecklist': this.lang.MENU.UVD + ' / ' + this.lang.MENU.refurbishmentFinalChecklist,
      'refurbishmentJobCardApproval': this.lang.MENU.UVD + ' / ' + this.lang.MENU.refurbishmentJobCardApproval,
      'refurbishmentJobCardCreate': this.lang.MENU.UVD + ' / ' + this.lang.MENU.refurbishment + ' / ' + this.lang.MENU.refurbishmentCreate,
      'spareAllocation': this.lang.MENU.UVD + ' / ' + this.lang.MENU.spareAllocation,
      'mechanicAllocation': this.lang.MENU.UVD + ' / ' + this.lang.MENU.mechanicAllocation,
      "vehicleValuation": this.lang.MENU.UVD + ' / ' + this.lang.MENU.vehicleValuation,
      "vehicleValuationCreate": this.lang.MENU.UVD + ' / ' + this.lang.MENU.vehicleValuation,
      "adfscClaimReport": this.lang.MENU.service + ' / ' + this.lang.MENU.adfscClaimReport,
      "approval": this.lang.MENU.service + ' / ' + this.lang.MENU.approval,
      "salesEnquiry": this.lang.MENU.salesEnquiry,
      "salesEnquiryCreate": this.lang.MENU.salesEnquiry,
      "salesFollowUp": this.lang.MENU.salesFollowUp,
      "salesFollowUpCreate": this.lang.MENU.salesFollowUp + ' / ' + this.lang.MENU.salesFollowUpCreate,
      "vehicleSales": this.lang.MENU.vehicleSales,
      "vehicleSalesCreate": this.lang.MENU.vehicleSales + ' / ' + this.lang.MENU.vehicleSalesCreate,
      "serviceReminder": this.lang.MENU.serviceRemainder,
      "serviceReminderDetail": this.lang.MENU.serviceRemainder + ' / ' + this.lang.MENU.serviceReminderDetail,
      "insurance": this.lang.MENU.insurance,
      "floorStock": 'Floor Stock',
      "uvdFollowup": this.lang.MENU.UVD + ' / ' + this.lang.SERVICES.followup,
      "uvdJobCard": this.lang.MENU.UVD + ' / ' + this.lang.MENU.service + ' / ' + this.lang.MENU.jobCard,
      "uvdJobCardInvoice": this.lang.MENU.UVD + ' / ' + this.lang.MENU.service + ' / ' + this.lang.MENU.jobCardInvoice,
      "uvdServiceReminder": this.lang.MENU.UVD + ' / ' + this.lang.MENU.service + ' / ' + this.lang.MENU.serviceRemainder,
      'uvdServiceAppoint': this.lang.MENU.UVD + ' / ' + this.lang.MENU.service + ' / ' + this.lang.MENU.serviceAppointment,
      "uvdDirectInvoice": this.lang.MENU.UVD + ' / ' + this.lang.SERVICES.parts + ' / ' + this.lang.PART.directInvoice,
      "uvdgrnOthers": this.lang.MENU.UVD + ' / ' + this.lang.SERVICES.parts + ' / ' + this.lang.PART.grnOther,
      "uvdgrnAps": this.lang.MENU.UVD + ' / ' + this.lang.SERVICES.parts + ' / ' + this.lang.PART.grnAps,
      "counterWarrantyClaim": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.counterWarrantyClaim,
      "directInvoice": this.lang.SERVICES.parts + ' / ' + this.lang.PART.directInvoice,
      "purchaseOrder": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.purchaseOrder,
      "partSalesReturn": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.partSalesReturn,
      "physicalInventoryCheck": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.physicalInventoryCheck,
      "partStockTransfer": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.partStockTransfer,
      "partStockReceipt": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.partStockReceipt,
      "partClaim": this.lang.SERVICES.parts + ' / ' + this.lang.SERVICES.partClaim,
      "ndpMrpMaster": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.ndpMrpMaster,
      "accessoryGrn": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.MENU.accessoryGrn,
      "grnTvs": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.MENU.grnTvs,
      "grnAmd": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.MENU.grnAmd,
      "grnOthers": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.PART.grnOther,
      "searchPurchaseReturn": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.MENU.searchPurchaseReturn,
      "grnAps": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.PART.grnAps,
      "partToPartGrn": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.MENU.partToPartGrn,
      "spareQuotation": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.saleOrder + ' / ' + this.lang.MENU.spareQuotation,
      "saleOrder": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.saleOrder,
      "pickSlip": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.saleOrder + ' / ' + this.lang.MENU.pickSlip,
      "pickSlipInvoice": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.saleOrder + ' / ' + this.lang.MENU.pickSlipInvoice,
      "partHelp": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.partHelp,
      "uvdMaster": this.lang.GENERAL.uvdMaster,
      "warehouseMaster": this.lang.UVD.warehouseMaster,
      "masterSpares": this.lang.GENERAL.master + ' / ' + this.lang.GENERAL.spares + ' / ' + this.lang.GENERAL.partMaster,
      "warehousePartMaster": this.lang.GENERAL.master + '/'+ this.lang.GENERAL.warehousePartMaster,
      "warehousePartPrice": this.lang.GENERAL.master + '/'+ this.lang.GENERAL.warehousePartPrice,
      "warehousePartCost": this.lang.GENERAL.master + '/'+ this.lang.GENERAL.warehousePartCost,
      "masterPrice": this.lang.GENERAL.master + ' / ' + this.lang.GENERAL.spares + ' / ' + this.lang.MENU.priceMaster,
      "costMaster": this.lang.GENERAL.master + ' / ' + this.lang.GENERAL.spares + ' / ' + this.lang.MENU.costMaster,
      "vendorMaster": this.lang.GENERAL.master + ' / ' + this.lang.MASTER.vendorMaster,
      "warehouseVendorMaster": this.lang.GENERAL.master + ' / ' + this.lang.MASTER.warehouseVendorMaster,
      "empMaster": this.lang.GENERAL.master + ' / ' + this.lang.MASTER.empMaster,
      "poGRNOther": this.lang.SERVICES.parts + ' / ' + this.lang.MENU.grn + ' / ' + this.lang.PART.poGRNOther,
      "masterLabourPrice": this.lang.GENERAL.master + ' / ' + this.lang.MENU.service + ' / ' + this.lang.MASTER.labourPrice,
      "viewLedgerEntry": this.lang.ACCOUNTS.accounts + ' / ' + this.lang.ACCOUNTS.ledgerEntry,
      "drivexInvoice": this.lang.PART.driveXAps,
      "drivexpurchaseOrder": this.lang.PART.drivexPO,
      "drivexSaleOrder": this.lang.PART.drivexSO,
      "drivexSaleOrderCreate": this.lang.PART.drivexSOC,
      "deliveryOrder": this.lang.PART.deliveryOrder,
      "drivexGRN": this.lang.PART.drivexGRN,
      "deliveryChalan": this.lang.PART.deliveryChalan,
      "deliveryChalanGrn": this.lang.PART.deliveryChalanGrn,
      "dealerpurchaseOrder": this.lang.PART.dealerPO,
      "dealerOnboard": this.lang.PART.dealerOnboard,
      "packStatement": this.lang.PART.packStatement,
      "vehicleBulkUpload": this.lang.PART.vehicleBulkUpload,
      "partBulkUpload": this.lang.PART.partBulkUpload,
      "invoiceList": this.lang.PART.invoiceOrder,
      "fullFillmentSales": this.lang.PART.fullFillmentSales,
      "dropShipmentSales": this.lang.PART.dropShipmentSales,
      "warehouseStock": this.lang.PART.warehouseStock,
      "rackMaster":this.lang.UVD.warehouseMaster,
      "uomMaster":this.lang.UVD.uomMaster,
      "brandMaster":this.lang.UVD.brandMaster,
      "hsnMaster":this.lang.UVD.hsnMaster,
      "hsnPercentage":this.lang.UVD.hsnPercentage,
      "websiteImageOrdering":this.lang.MENU.websiteImageOrdering,
      "vehicleListPage":this.lang.UVD.vehicleListPage,
      "vehicleUpload":this.lang.MENU.vehicleUpload
    }

    return this.titles[title];
  }


  pendingjobCardStatus() {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '40%',
      minHeight: '150px',
      maxHeight: '300px',
      height: 'auto',
      data: {
        type: 'first'
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.obj) {
        let reasonID = result.obj;
        this.router.navigate(['service/jobcard/pending-jobcard-status']);
      }
    });
  }

  /**
   * Logout the user
   */
  logout() {
    if (CommonService.getUserData().LOGIN_ID == "Service") {
      let params = new HttpParams();
      params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
        .set('BranchId', CommonService.getUserData().BRANCH_ID)
        .set('UserId', CommonService.getUserData().USER_ID);
      this.jobCardService.getPendingJobcardDetails(params).subscribe((resp: any) => 
      {
        if (resp && resp.statusCode === 200 && resp.data) {
          this.jobcardDetails = resp.data.length > 0 ? resp.data : [];
          if (this.jobcardDetails != null && this.jobcardDetails.length > 0) {
            this.pendingjobCardStatus();

          }
          else {
            const dialogRef = this.dialog.open(LogoutDialogComponent, {
              width: '400px', disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                localStorage.clear();
                this.toastr.success(this.lang.Message.LOGOUT_SUCCESS);
                if(this.isDealerLogin == "false"){
                  this.router.navigate(['/session/dealer']);
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);
                }
                if(this.isDealerLogin == "true"){
                  this.router.navigate(['/session/login']);
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);
                }
              }
            });
          }
        }
      }, error => {
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      }
      );
    }
    else {
      const dialogRef = this.dialog.open(LogoutDialogComponent, {
        width: '400px', disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          localStorage.clear();
          this.toastr.success(this.lang.Message.LOGOUT_SUCCESS);
          if(this.isDealerLogin == "false"){
            if(this.isDTCLogin == "true") {
              this.router.navigate(['/session/digitalfactory']);
              setTimeout(() => {
                window.location.reload();
              }, 100);
            }
            else {
              this.router.navigate(['/session/dealer']);
              setTimeout(() => {
                window.location.reload();
              }, 100);
            }
          }
          if(this.isDealerLogin == "true"){
            this.router.navigate(['/session/login']);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        }
      });
    }


  }
  openColorPicker() {
    const dialogRef = this.dialog.open(ColorPickerComponent, {
      width: '30%',
      height: '470px',
      panelClass: 'colorPickerBody',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  styles: [`
  .basicInfoView {
    background-color: #f6fbff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 140px);
    overflow-x: hidden;
    overflow-y: auto;
}
.sectionHeader {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  background: var(--inner-header);
  opacity: 0.9;
  color: #202020;
  font-family: var(--fontName);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 12px 12px 22px;
}
label {
  color: #00599d !important;
}
span {
  opacity: 0.88;
  color: #1c1c1c;
  font-family: var(--fontName);
  font-size: 12px;
  font-weight: 500;
}`],
  template: `
  <div class="basicInfoView ">
    <div class="sectionHeader">
    {{lang?.GENERAL?.logout}}
    </div>
    <div class="row mlr-0 p-12">
    {{lang?.GENERAL?.wantToLogout}}
    </div>
    <div class="row mlr-0 p-12 flex align-right">
       <button class="cancelBtn mr-12" (click)="close()" title="{{lang?.GENERAL?.closeBtn}}">{{lang?.GENERAL?.closeBtn}}</button>
       <button class="createJCBtn" (click)="logout()" title="{{lang?.GENERAL?.logout}}">{{lang?.GENERAL?.logout}}</button>
    </div>
  </div>
`,
})
export class LogoutDialogComponent {
  public lang: any;
  public activeThem = 'green';
  constructor(public dialogRef: MatDialogRef<LogoutDialogComponent>,private loginService:LoginService) { }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  close(): void {
    this.dialogRef.close(false);
  }
  logout() {
    //localStorage.clear();
    this.loginService.logout();
    this.dialogRef.close(true);
  }

}
