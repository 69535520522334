/**
 * Created BY HMSPL 
 * Last Modified By Balaji on 31/08/2018
 * Last Modified Description : Added static data
 */
 export const data = {
  dealerRoles:
      [
          // {
          //     name: "TVS Admin",
          //     value: "1"
          // },
          {
              name: "Dealer Admin",
              value: "2"
          },
          {
              name: "Admin",
              value: "3"
          },
          {
              name: "Accounts",
              value: "4"
          },
          {
              name: "Sales",
              value: "5"
          },
          {
              name: "Support",
              value: "6"
          }, {
              name: "Service",
              value: "7"
          }, {
              name: "Spares",
              value: "8"
           }, 
          //{
          //     name: "TVS Field Staff",
          //     value: "9"
          // },
          // {
          //     name: "TVS Marketing",
          //     value: "10"
          // },
          {
              name: "Country Manager",
              value: "11"
          }
  ],
  dtcRoles:
      [
          {
            name: "Technician",
            value: "12"
          },
          {
            name: "Support",
            value: "13"
          },
          {
            name: "Admin",
            value: "3"
         },
          {
            name: "L1 Approver",
            value: "14"
          },
          {
            name: "L2 Approver",
            value: "15"
          },
          {
            name: "L3 Approver",
            value: "16"
          },
        ],
  language: [
      {
          value: 'tn',
          name: 'TAMIL'
      },
      {
          value: 'en',
          name: 'ENGLISH'
      }
      // ,
      // {
      //     value: 3,
      //     name: 'HINDI'
      // },
      // {
      //     value: 4,
      //     name: 'BENGALI'
      // },
      // {
      //     value: 5,
      //     name: 'GUJARATHI'
      // },
      // {
      //     value: 6,
      //     name: 'KANNADA'
      // },
      // {
      //     value: 7,
      //     name: 'PUNJABI'
      // },
      // {
      //     value: 8,
      //     name: 'TELUGU'
      // },
      // {
      //     value: 9,
      //     name: 'MALAYALAM'
      // },
      // {
      //     value: 10,
      //     name: 'BAHASA_INDONESIA'
      // }
  ],
  reminderStatus: [{
      name: 'Reminder Sent',
      value: 1
  }, {
      name: 'Phone Call Pending',
      value: 2
  }, {
      name: 'Letter Pending For Print',
      value: 3
  }, {
      name: 'Followed Up',
      value: 4
  }, {
      name: 'Closed',
      value: 5
  }, {
      name: 'Reminder Pending',
      value: 6
  }],
  basedOnDateRange: [{
      name: 'Invoice Date',
      value: false
  }, {
      name: 'Sale Date',
      value: true
  }],
  reminderType: [{
      name: 'Only Reminder Customer ',
      value: false
  }, {
      name: 'Only Followup Customer ',
      value: true
  }
  ],
  reminderMode: [{
      name: 'SMS',
      value: 'FollowUpType.SMS'
  }, {
      name: 'Phone',
      value: 'FollowUpType.Phone'
  }
  ],
  serviceType: [{
      name: 'Free Service',
      value: 0
  },
  {
      name: 'PAID SERVICE',
      value: 1
  },
  {
      name: 'Sale Date Less than 2 Yrs',
      value: 2
  },
  {
      name: 'Sale Date Between 2 and 5 Yrs',
      value: 3
  },
  {
      name: 'Sale Date Greater than 5 Yrs',
      value: 4
  }
  ],
  FollowUpType: [{
      name: 'Phone',
      value: false
  },

  {
      name: 'InPerson',
      value: true
  }
  ],
  soldIn: [{
      name: 'ShowRoom',
      value: 'ShowRoom'
  }, {
      name: 'WorkShop',
      value: 'WorkShop'
  }, {
      name: 'ThroughAD',
      value: 'ThroughAD'
  }],
  leakages: [{
      name: 'InFollowUp',
      value: 3
  },
  {
      name: 'CustomerNotInterested',
      value: 4
  }],
  completeStatus: [
      {
          name: 'Complete',
          code: 1
      },
      {
          name: 'NotStarted',
          code: 0
      },
      {
          name: 'Pending',
          code: 2
      },
      {
          name: 'PendingRecallRefit',
          code: 3
      }
  ],
  invoiceNames: [
      {
          name: 'Customer',
          value: 1
      },
      {
          name: 'EndUser',
          value: 2
      },
      {
          name: 'Payer',
          value: 3
      },
      {
          name: 'Insurance',
          value: 4
      }
  ],
  amcStatus: [
      {
          name: 'Open',
          value: 0
      },
      {
          name: 'Closed',
          value: 1
      },
      {
          name: 'Cancelled',
          value: 2
      }
  ],
  crmStatus: [
      {
          name: 'Open',
          value: 0
      },
      {
          name: 'Closed',
          value: 1
      }
  ],
  jcInvoiceStatus: [
      {
          name: 'Open',
          value: 1
      },
      // {
      //     name: 'Closed',
      //     value: 2
      // },
      {
          name: 'Cancel',
          value: 3
      }
  ],
  billTypes: [
      {
          name: 'Cash',
          value: 1
      },
      {
          name: 'Credit',
          value: 2
      }
  ],
  paymentMode: [
      {
          name: 'Cash',
          value: 1
      },
      {
          name: 'Credit Card',
          value: 2
      },
      {
          name: 'DD',
          value: 3
      },
      {
          name: 'Cheque',
          value: 4
      },
      {
          name: 'Transfer',
          value: 5
      }
  ],
  appointmentStatus: [
      {
          name: 'Open',
          value: 0
      }, {
          name: 'Closed',
          value: 1
      },
      {
          name: 'Automatic closure',
          value: 2
      }, {
          name: 'Jobcard closure',
          value: 3
      }
  ],
  outworkMemoStatus: [
      {
          name: 'Job Not Sent',
          value: 0
      }, {
          name: 'Job Sent',
          value: 1
      }, {
          name: 'JobComplete',
          value: 2
      }
  ],
  outworkMemoFilterStatus: [
      {
          name: 'Open',
          value: 0
      }, {
          name: 'Printed',
          value: 1
      }, {
          name: 'Closed',
          value: 2
      },
  ],
  outworkPaymentFilterStatus: [
      {
          name: 'Open',
          value: 0
      }, {
          name: 'Paid',
          value: 1
      }
  ],
  issueMode: [
      {
          name: 'Normal',
          code: 1
      },
      {
          name: 'Warranty',
          code: 2
      },
      {
          name: 'FOC',
          code: 3
      },
      {
          name: 'GoodWill',
          code: 4
      },
      {
          name: 'Recall-Refit',
          code: 5
      },
      {
          name: 'Transit',
          code: 6
      },
      {
          name: 'Insurance',
          code: 7
      },
      {
          name: 'SupplierWarranty',
          code: 8
      },
      {
          name: 'AccessoryWarranty',
          code: 9
      }
  ],
  jcStatus: [
      {
          name: 'UnAllocated',
          value: 0
      },
      {
          name: 'Allocated',
          value: 1
      },
      {
          name: 'Suspended',
          value: 2
      },
      {
          name: 'Cancelled',
          value: 3
      },
      {
          name: 'Invoiced',
          value: 4
      },
      {
          name: 'JobComplete',
          value: 5
      },
      {
          name: 'Closed',
          value: 6
      }
  ],
  amcList: [
      {
          name: 'Exisging AMC',
          value: 1,
      }, {
          name: 'New AMC',
          value: 2,
      }, {
          name: 'Extended Warranty',
          value: 4,
  }],
  reasonForClosing: [
      {
          name: 'Does Not Exist/ Wrong No',
          value: 2
      },
      {
          name: 'Customer contacted & Appointment Taken',
          value: 2
      },
      {
          name: 'Vehicle Serviced in other AMD/ AD',
          value: 3
      },
      {
          name: 'Service at PGM',
          value: 4
      },
      {
          name: 'Transfer to other town/ Vehicle not in town',
          value: 5
      },
      {
          name: 'Service Expensive',
          value: 6
      },
      {
          name: 'Enough Follow up done',
          value: 7
      }
  ],
  manufacture: [
      {
          name: ' TVS',
          code: 1
      },
      {
          name: ' Others',
          code: 2
      },
      {
          name: ' BajajAuto',
          code: 3
      },
      {
          name: ' HeroHonda',
          code: 4
      },
      {
          name: ' Honda',
          code: 5
      },
      {
          name: ' Kinetic',
          code: 6
      },
      {
          name: 'LML',
          code: 7
      },
      {
          name: 'Suzuki',
          code: 8
      },
      {
          name: ' HeroMotor',
          code: 9
      },
      {
          name: ' HeroMajestic',
          code: 10
      },
      {
          name: ' Yamaha',
          code: 11
      },
      {
          name: ' Escort',
          code: 12
      },
      {
          name: ' Eicher',
          code: 13
      },
      {
          name: ' OtherOE',
          code: 14
      },
  ],
  jobType: [
      {
          name: '1st Free Service',
          value: 1
      },
      {
          name: '2nd Free Service',
          value: 2
      },
      {
          name: '3rd Free Service',
          value: 3
      },
      {
          name: '4th Paid Service',
          value: 4
      },
      {
          name: '5th Paid Service',
          value: 5
      },
      {
          name: '6th Paid Service',
          value: 6
      },
      {
          name: '7th Paid Service',
          value: 7
      },
      {
          name: 'Bonus',
          value: 8
      },
      {
          name: 'Post Warranty',
          value: 9
      },
      {
          name: 'Rework',
          value: 10
      },
      {
          name: 'Major Repair',
          value: 11
      },
      {
          name: 'Running Repair',
          value: 12
      },
      {
          name: 'Accident Repair',
          value: 13
      },
      {
          name: 'PDI',
          value: 14
      },
      {
          name: '4th Free Service',
          value: 15
      },
      {
          name: '5th Free Service',
          value: 16
      },
      {
          name: '8th Paid Service',
          value: 17
      },
      {
          name: '9th Paid Service',
          value: 18
      },
      {
          name: '10th Paid Service',
          value: 19
      },
      {
          name: '11th Paid Service',
          value: 20
      },
      {
          name: '6th Bonus Service',
          value: 21
      },
      {
          name: '7th Paid Service',
          value: 22
      },
      {
          name: '8th Bonus Service',
          value: 23
      },
      {
          name: 'Warranty',
          value: 24
      },
      {
          name: '7th Free Service',
          value: 25
      },
      {
          name: '9th Free Service',
          value: 26
      },
      {
          name: 'PSF',
          value: 27
      },
      {
          name: '6th Free Service',
          value: 28
      },
      {
          name: '8th Free Service',
          value: 29
      },
      {
          name: '10th Free Service',
          value: 30
      },
      {
          name: 'PAID SERVICE',
          value: 31
      }
  ],
  warrantyCliamStatus: [
      {
          value: 0,
          name: "Open"
      },
      {
          value: 1,
          name: "SAP RSO Received"
      },
      {
          value: 2,
          name: "Credit Note Received"
      },
      {
          value: 3,
          name: "Closed"
      },
      {
          value: 4,
          name: "SQL Confirmed"
      },
      {
          value: 6,
          name: "AD Confirmed"
      }
  ],
  transitClaimStatus: [
      {
          value: 0,
          name: "Open"
      },
      {
          value: 1,
          name: "Claim Submitted"
      },
      {
          value: 2,
          name: "SAP RSO Received"
      },
      {
          value: 3,
          name: "Closed"
      }
  ],
  ascWarrantyStatus: [{
      value: 0,
      name: "Open"
  }, {
      value: 1,
      name: "Confirmed"
  }, {
      value: 2,
      name: "Credit_Note_Raised"
  }, {
      value: 3,
      name: "Closed"
  }],
  labourCat: [
      {
          "LABOUR_CAT_ID": "BEN",
          "DESCRIPTION": "Benchwork",
          "ACTIVE": true
      },
      {
          "LABOUR_CAT_ID": "DLR",
          "DESCRIPTION": "Dealer Owned",
          "ACTIVE": true
      },
      {
          "LABOUR_CAT_ID": "OUT",
          "DESCRIPTION": "Outwork",
          "ACTIVE": true
      },
      {
          "LABOUR_CAT_ID": "WAT",
          "DESCRIPTION": "Water wash",
          "ACTIVE": true
      }
  ],
  enquiryMode: [
      {
          value: 1,
          name: "Telephone"
      },
      {
          value: 2,
          name: "Walk in"
      },
      {
          value: 3,
          name: "EMail"
      },
      {
          value: 4,
          name: "Direct Marketing"
      },
      {
          value: 5,
          name: "MMP Project"
      },
      {
          value: 6,
          name: "WOW Project"
      },
      {
          value: 7,
          name: "Outdoor Activity"
      },
      {
          value: 8,
          name: "Rural Program"
      },
      {
          value: 12,
          name: "Moped Program"
      }
  ],
  enquirySaleMode: [
      {
          value: 1,
          name: "HP"
      },
      {
          value: 2,
          name: "Exchange"
      },
      {
          value: 3,
          name: "HPandExchange"
      },
      {
          value: 4,
          name: "Cash"
      }
  ],
  dmsModules:[
      {
          name: 'Dashboard',
          icon: 'assets/icons/dashboard.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false
        }, 
        {
          name: 'Service',
          icon: 'assets/icons/dashboard.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false
        },
        {
          name: 'Service & Parts',
          icon: 'assets/icons/menu_uvd.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        {
          name: 'Drivex Masters',
          icon: 'assets/icons/menu_uvd.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        {
          name: 'Drivex Drop Shipment',
          icon: 'assets/icons/menu_uvd.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        {
          name: 'Drivex Warehouse',
          icon: 'assets/icons/menu_uvd.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        {
          name: 'Drivex Fulfillment',
          icon: 'assets/icons/menu_uvd.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        
        {
          name: 'Reports',
          icon: 'assets/icons/menu_report.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        },
        {
          name: 'Call Log',
          icon: 'assets/icons/menu_support.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        }
        ,{
          name: 'Admin',
          icon: 'assets/icons/menu_admin.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false
        },
        {
          name: 'Help',
          icon: 'assets/icons/menu_help.png',
          bgColor: ' #03a9f4',
          state: '',
          isActive: false
        },
        {
          name: 'E-Support',
          icon: 'assets/icons/menu_support.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false
        },
        {
          name: 'Masters',
          icon: 'assets/icons/menu_master.png',
          bgColor: '#03a9f4',
          state: 'http://10.121.212.244:86/Masters/CustomerSearch.aspx',
          isActive: false
        },
        {
          name: 'Sales',
          icon: 'assets/icons/menu_sales.png',
          bgColor: '#03a9f4',
          state: 'http://10.121.212.244:86/Sales/Enquiry.aspx',
          isActive: false
        },
        {
          name: 'Parts',
          icon: 'assets/icons/menu_parts.png',
          bgColor: '#03a9f4',
          state: 'http://10.121.212.244:86/Parts/DirectInvoiceSearch.aspx',
          isActive: false
        },
        {
          name: 'Accounts',
          icon: 'assets/icons/menu_account.png',
          bgColor: '#03a9f4',
          // bgColor: '#607D8B',
          state: 'http://10.121.212.244:86/Accounts/ReceiptVoucherSearch.aspx',
          isActive: false
        },
        {
          name: 'Reports1',
          icon: 'assets/icons/menu_report.png',
          bgColor: '#03a9f4',
          state: 'http://10.121.212.244:86/Reports/RptSales_V501_TVS.aspx',
          isActive: false
        },
        {
          name: 'Dx Accounts',
          icon: 'assets/icons/menu_account.png',
          bgColor: '#03a9f4',
          state: '',
          isActive: false 
        }
  ],
  subMenu:[
      {
        name: 'Drivex Sale Order',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/parts/purchase-order-list/drivex-sale-order-list',
        width:''
      },
      {
        name: 'Parts Stock',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/uvdreports/reports/warehouse-stock',
        width:''
      },
      {
        name: 'Invoice Drop Shipment',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/uvdreports/reports/dropshipment-sales',
        width:''
      },
      {
        name: 'Invoice Fulfillment',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/uvdreports/reports/fullfillment-sales',
        width:''
      },
      {
        name: 'Drivex Delivery Advice',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/parts/purchase-order-list/delivery-order-list',
        width:''
      },
      {
        name: 'Drivex Pick List',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/parts/purchase-order-list/pick-statement-list',
        width:''
      },
      {
        name: 'Drivex Pack List',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/parts/purchase-order-list/pack-statement-list',
        width:''
      },
      {
        name: 'Drivex Invoice',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/parts/purchase-order-list/invoice-order-list',
        width:''
      },
      {
        name: 'Menu Management',
        menuName: 'Admin',
        isCustomize: false,
        icon: 'assets/icons/sub-outwork-payment.png',
        state: '/admin/menu-management',
        width:''
      },
      {
        name: 'User Mapping',
        menuName: 'Admin',
        isCustomize: false,
        icon: 'assets/icons/menu_procurement.png',
        state: '/admin/user-mapping',
        width:''
      },
      {
        name: 'Dealer Master',
        menuName: 'MASTERS',
        isCustomize: false,
        icon: 'assets/icons/sub-job-card.png',
        state: '/admin/dealer-master',
        width:''
      },
      {
        name: 'Rack Master',
        menuName: 'Spares',
        isCustomize: false,
        icon: 'assets/icons/sub-service-appoinment.png',
        state: '/master/uvd-master/rack-master',
        width:''
      },
      {
        name: 'HSN Code',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-service-appoinment.png',
        state: '/master/uvd-master/hsn-code-master',
        width:''
      },
      {
        name: 'HSN Code Tax',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-service-appoinment.png',
        state: '/master/uvd-master/hsn-percentage-master',
        width:''
      },
      {
        name: 'UOM',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-service-appoinment.png',
        state: '/master/uvd-master/uom-master',
        width:''
      },
      {
        name: 'Brand',
        menuName: 'Drivex Parts',
        isCustomize: false,
        icon: 'assets/icons/sub-job-card.png',
        state: '/master/uvd-master/brand-master-list',
        width:''
      },
      {
        name: 'Branch Master',
        menuName: 'Spares',
        isCustomize: false,
        icon: 'assets/icons/sub-job-card.png',
        state: '/master/uvd-master/branch-master',
        width:''
      },
      
      {
        name: 'User',
        menuName: 'Admin',
        isCustomize: false,
        icon: 'assets/icons/menu_procurement.png',
        state: '/admin/user-management',
        width:''
      },
      {
          name: 'Service Reminder',
          menuName: 'SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-service-reminder.png',
          state: '/service/servicefollowup',
          width:''
        },
        {
          name: 'Job Card',
          menuName: 'SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/service/jobcard',
          width:''
        },
        {
          name: 'Job Card Invoice',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-job-card-invoice.png',
          state: '/service/jobcardinvoice',
          width:''

        },
        {
          name: 'Service Appointment',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/service/serviceappointment',
          width:''

        },
        {
          name: 'Service AMC',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/service/serviceamc',
          width:''

        },
        {
          name: 'Vehicle Service History',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-vehi-service-history.png',
          state: '/service/vehicleservicehistory',
          width:''
        },        
        {
          name: 'AD FSC Claim Report',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/service/adfscclaimreport',
          width:''
        },
        {
          name: 'Appointment Request',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/service/approval',
          width:''
        },
        {
          name: 'PND EOW Allocate',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/service/pnd',
          width:''
        },
        {
          name: 'PSF',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-psf.png',
          state: '/service/psf',
          width:''

        },
        {
          name: 'CRM Welcome Kit',
          isCustomize: false,
          menuName: 'SERVICE',
          icon: 'assets/icons/sub-crm-welcome-kit.png',
          state: '/service/crmwelcomekit',
          width:''

        },
        {
          name: 'Transit Claim',
          isCustomize: false,
          menuName: 'CLAIMS',
          icon: 'assets/icons/sub-transit-claim.png',
          state: '/service/claims/transit-claim',
          width:''

        },
        {
          name: 'ASC Warranty Claim',
          isCustomize: false,
          menuName: 'CLAIMS',
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: '/service/claims/asc-warranty-claim',
          width:''

        },
        {
          name: 'Dealer FSC Claim',
          isCustomize: false,
          menuName: 'CLAIMS',
          icon: 'assets/icons/sub-dealer-fsc-claim.png',
          state: '/service/claims/dealer-fsc-claim',
          width:''

        },
        {
          name: 'Warranty Claim',
          isCustomize: false,
          menuName: 'CLAIMS',
          icon: 'assets/icons/sub-warrenty-claim.png',
          state: '/service/claims/warranty-claim',
          width:''

        },
        {
          name: 'ASC FSC Claim',
          isCustomize: false,
          menuName: 'CLAIMS',
          icon: 'assets/icons/sub-asc-fsc-claim.png',
          state: '/service/claims/asc-fsc-claim',
          width:''

        },
        {
          name: 'Outwork Memo',
          isCustomize: false,
          menuName: 'OUTWORK PROCESS',
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/service/outwork/outwork-memo',
          width:''

        },
        {
          name: 'Outwork Payment',
          isCustomize: false,
          menuName: 'OUTWORK PROCESS',
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/service/outwork/outwork-payment',
          width:''
        },
        {
          name: 'Counter Warranty Claim',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/counter-warranty-claim-list',
          width:''
        },
        {
          name: 'Issue Parts to Job Card',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/issue-parts-list',
          width:''
        },
        
        {
          name: 'Purchase Order',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list',
          width:''
        },
        {
          name: 'Direct Invoice',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list',
          width:''
        },
        {
          name: 'Drivex Inward Invoice',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/drivex-invoice-list',
          width:''
        },
        {
          name: 'Drivex Outward Invoice',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/drivex-outlet-invoice-list',
          width:''
        },
        {
          name: 'Warehouse Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/warehouse-purchase-order-list',
          width:''
        },
        {
          name: 'Vendor Invoice Info',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/wh-invoice-list',
          width:''
        },
        {
          name: 'Warehouse GRN',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/wh-grn-list/wh-grn-list',
          width:''
        },
        {
          name: 'Part Sales Return',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/part-sales-return-list',
          width:''
        },
        {
          name: 'Part Help',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/part-help-list',
          width:''
        },
        {
          name: 'Physical Inventory Check',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/physical-inventory-check-list',
          width:''
        },
        {
          name: 'Part Stock Transfer',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/part-stock-transfer-list',
          width:''
        },
        {
          name: 'Part Stock Receipt',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/part-stock-receipt-list',
          width:''
        },
        {
          name: 'Parts Claim',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/part-claim-list',
          width:''
        },
        {
          name: 'NDP-MRP Master',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/ndp-mrp-master-list',
          width:''
        },
        {
          name: 'Issue Vehicle Accessory',
          isCustomize: false,
          menuName: 'PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/issue-vehicle-accessory-list',
          width:''
        },
        {
          name: 'Accessory GRN',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/accessory-grn-list',
          width:''
        },
        {
          name: 'GRN TVS',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-tvs-list',
          width:''
        },
        {
          name: 'GRN AMD',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-amd-list',
          width:''
        },
        {
          name: 'GRN Others',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-others-list',
          width:''
        },
        {
          name: 'Search Purchase Return',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/search-purchase-return-list',
          width:''
        },
        {
          name: 'GRN APS',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-aps-list',
          width:''
        },
        {
          name: 'Part To Part GRN',
          isCustomize: false,
          menuName: 'Parts GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/part-to-part-grn-list',
          width:''
        },
        {
          name: 'Spare Quotation',
          isCustomize: false,
          menuName: 'Parts Sale Order Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/sale-order/spare-quotation-list',
          width:''
        },
        {
          name: 'Sale Order',
          isCustomize: false,
          menuName: 'Parts Sale Order Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/sale-order/sale-order-process-list',
          width:''
        },
        {
          name: 'Pick Slip',
          isCustomize: false,
          menuName: 'Parts Sale Order Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/sale-order/pick-slip-list',
          width:''
        },
        {
          name: 'PickSlip Invoice',
          isCustomize: false,
          menuName: 'Parts Sale Order Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/sale-order/pick-slip-invoice-list',
          width:''
        },
        {
          name: 'Enquiry',
          isCustomize: false,
          menuName: 'Sales Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-process/enquiry-list',
          width:''
        },
        {
          name: 'Quotation',
          isCustomize: false,
          menuName: 'Sales Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-process/quotation-list',
          width:''
        },
        {
          name: 'Booking',
          isCustomize: false,
          menuName: 'Sales Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-process/booking-list',
          width:''
        },
        {
          name: 'FollowUp',
          isCustomize: false,
          menuName: 'Sales Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-process/sales-followup-list',
          width:''
        },
        {
          name: 'Quick Invoice',
          isCustomize: false,
          menuName: 'Sales Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-process/quick-invoice',
          width:''
        },
        {
          name: 'Accessory Invoice',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/accessory-invoice-list',
          width:''
        },
        {
          name: 'Insurance Invoice',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/insurance-invoice-list',
          width:''
        },
        {
          name: 'Multi Vehicle Invoice',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/multi-vehicle-invoice',
          width:''
        },
        {
          name: 'Registration Invoice',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/registration-invoice-list',
          width:''
        },
        {
          name: 'Vehicle Invoice',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/vehicle-invoice-list',
          width:''
        },
        {
          name: 'Vehicle Invoice Cancellation',
          isCustomize: false,
          menuName: 'Invoice Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/invoice-process/vehicle-invoice-cancellation',
          width:''
        },
        {
          name: 'MainDealer Invoice',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/main-dealer-invoice',
          width:''
        },
        {
          name: 'Purchase Return',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/purchase-return-list',
          width:''
        },
        {
          name: 'Sales GRN TVS',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/sales-grn-tvs-list',
          width:''
        },
        {
          name: 'Sales Other Vendor GRN',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/sales-other-vendor-grn-list',
          width:''
        },
        {
          name: 'Search TVS Invoice',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/search-tvs-invoice-list',
          width:''
        },
        {
          name: 'Sales GRN For AMD',
          isCustomize: false,
          menuName: 'GRN Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/sales-grn-amd-list',
          width:''
        },
        {
          name: 'Sales PSF',
          isCustomize: false,
          menuName: 'PSF Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/psf-process/sales-psf-list',
          width:''
        },
        {
          name: 'Sales PSF Detractor',
          isCustomize: false,
          menuName: 'PSF Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/psf-process/sales-psf-detractor-list',
          width:''
        },
        {
          name: 'Accessory Return',
          isCustomize: false,
          menuName: 'Return Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/return-process/accessory-return',
          width:''
        },
        {
          name: 'Vehicle Return',
          isCustomize: false,
          menuName: 'Return Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/return-process/vehicle-return-list',
          width:''
        },
        {
          name: 'Generate Slip',
          isCustomize: false,
          menuName: 'RTO Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/rto-process/outslip-list',
          width:''
        },
        {
          name: 'RC Book Updation',
          isCustomize: false,
          menuName: 'RTO Process',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/rto-process/rc-book-list',
          width:''
        },
        {
          name: 'ASC Stock Transfer',
          isCustomize: false,
          menuName: 'Stock Transfer',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/stock-transfer/asc-stock-transfer-list',
          width:''
        },
        {
          name: 'Vehicle Stock Transfer',
          isCustomize: false,
          menuName: 'Stock Transfer',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/stock-transfer/vehicle-stock-transfer-list',
          width:''
        },
        {
          name: 'Journal Voucher',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/journal-voucher-list',
          width:''
        },
        {
          name: 'Extraction to Tally',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/extraction-totally-list',
          width:''
        },
        {
          name: 'Payment Voucher',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/payment-voucher-list',
          width:''
        },

        {
          name: 'Credit Note',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/creditsearch',
          width:''
        },

        {
          name: 'Debit Note',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/debitsearch',
          width:''
        },
        {
          name: 'Bank Adjustment',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/bankreconcilation/bank-adjustment',
          width:''
        },
        {
          name: 'Bank Reconciliation',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/bankreconcilation',
          width:''
        },       
        {
          name: 'CreditDedit Reconciliation',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/credit-debit-recon',
          width:''
        },

        {
          name: 'Receipt Voucher',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/receipt-voucher-list',
          width:''
        },
        {
          name: 'Self Hp Voucher',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/self-hp-voucher-list',
          width:''
        },
        {
          name: 'View Ledger Entries',
          isCustomize: false,
          menuName: 'Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/view-ledger-entry',
          width:''
        },
        {
          name: 'Commission Payment',
          isCustomize: false,
          menuName: 'Tracking Payment',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/tracking-payment/commission-payment-list',
          width:''
        },
        {
          name: 'Registration Payment',
          isCustomize: false,
          menuName: 'Tracking Payment',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/tracking-payment/registration-payment-list',
          width:''
        },
        {
          name: 'Insurance Payment',
          isCustomize: false,
          menuName: 'Tracking Payment',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/accounts/tracking-payment/insurance-payment-list',
          width:''
        },
        {
          name: 'Referral Vehicle',
          menuName: 'PROCUREMENT',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/uvd/procurement/referral-vehicle-list',
          width:'wpx-20'
        },
        {
          name: 'Procurement Vehicle',
          menuName: 'PROCUREMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_procurement.png',
          state: '/uvd/procurement',
          width:'wpx-20'
        },
        {
          name: 'Procurement Vehicles',
          menuName: 'PROCUREMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_procurement.png',
          state: '/uvd/procurement/procurement-list',
          width:'wpx-20'
        },
        {
          name: 'Refurbishment Jobcard',
          menuName: 'REFURBISHMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/uvd/refurbishment',
          width:'wpx-25'
        },
        {
          name: 'Vehicle Valuation',
          menuName: 'REFURBISHMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_vehicle_valuation.png',
          state: '/uvd/refurbishment/vehicle-valuation',
          width:'wpx-20'
        },
        {
          name: 'Spare Allocation',
          menuName: 'REFURBISHMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/uvd/spare-allocation-list',
          width:'wpx-25'
        },
        {
          name: 'Mechanic Allocation',
          menuName: 'REFURBISHMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/uvd/mechanic-allocation-list',
          width:'wpx-25'
        },
        {
          name: 'Job Card Final Checklist',
          menuName: 'REFURBISHMENT',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/uvd/refurbishment/refurbishment-final-checklist',
          width:'wpx-25'
        },
        {
          name: 'Sales Lead',
          menuName: 'UVD SALES',
          isCustomize: false,
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: '/uvd/uvd-sales',
          width:''
        },
        {
          name: 'Vehicle Listing Page',
          menuName: 'UVD SALES',
          isCustomize: false,
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: '/uvd/uvd-sales/vehicle-listing-page',
          width:''
        },
        {
          name: 'Floor Stock',
          menuName: 'UVD STOCK',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-sales/uvd-floor-stock',
          width:''
        },        
        {
          name: 'Parts Inventory Stock',
          menuName: 'UVD STOCK',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-sales/parts-inventory-stock',
          width:''
        },
        {
          name: 'Service Reminder',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-service-reminder.png',
          state: '/uvd/uvd-service/uvd-service-reminder',
          width:''
        },
        {
          name: 'PSF',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-service-reminder.png',
          state: '/uvd/uvd-service/uvd-service-psf',
          width:''
        },      

        {
          name: 'Service Appointment',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/uvd/uvd-service/uvd-service-appointment',
          width:''
        },
        {
          name: 'Vehicle Service History',
          isCustomize: false,
          menuName: 'UVD SERVICE',
          icon: 'assets/icons/sub-vehi-service-history.png',
          state: '/service/vehicleservicehistory',
          width:''
        },
        {
          name: 'Service Reminder',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-service-reminder.png',
          state: '/uvd/uvd-service/uvd-service-reminder',
          width:''
        },

        {
          name: 'Accessory GRN',
          menuName: 'UVD PARTS',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/accessory-grn-list',
          width:''
        },
        {
          name: 'GRN APS',
          menuName: 'UVD PARTS',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-aps-list',
          width:''
        },
        {
          name: 'DMS DX GRN',
          menuName: 'UVD PARTS',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/drivex-grn-list',
          width:''
        },
        {
          name: 'Drivex GRN',
          menuName: 'Drivex Parts',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/drivex-grn-list/drivex-grn-list',
          width:''
        },
        {
          name: 'GRN Others',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/grn-others-list',
          width:''
        },
        {
          name: 'Direct Invoice',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-job-card.png',
          state: '/parts/direct-invoice-list',
          width:''
        },
        {
          name: 'Dealer Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/dealer-purchase-order-list',
          // state: '/parts/purchase-order-list/vendor-purchase-order-list',
          // state: '/parts/purchase-order-list/pick-statement-list',
          // state: '/parts/purchase-order-list/pack-statement-list',
          width:''
        },
        {
          name: 'Drivex Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/drivex-purchase-order-list',
          // state: '/parts/purchase-order-list/vendor-purchase-order-list',
          // state: '/parts/purchase-order-list/pick-statement-list',
          // state: '/parts/purchase-order-list/pack-statement-list',
          width:''
        },
        {
          name: 'Warehouse',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/master/uvd-master/warehouse-master-list',
          width:''
        },
        {
          name: 'Customer',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/admin/warehouse-customer-master',
          width:''
        },
        {
          name: 'Vendor',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/master/common/warehouse-vendor-master-list',
          width:''
        },
        {
          name: 'Parts',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/master/spares/warehouse-part-master-list',
          width:''
        },
        {
          name: 'Parts Purchase Price',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/master/spares/warehouse-part-cost-list',
          width:''
        },
        {
          name: 'Parts Selling Price',
          isCustomize: false,
          menuName: 'Drivex Parts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/master/spares/warehouse-part-price-list',
          width:''
        },
        {
          name: 'Dealer Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/dealer-purchase-order-list',
          width:''
        },
        {
          name: 'Parts Stock',
          menuName: 'Drivex Accounts',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/uvdreports/reports/warehouse-stock',
          width:''
        },
        {
          name: 'Invoice Drop Shipment',
          menuName: 'Drivex Accounts',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/uvdreports/reports/dropshipment-sales',
          width:''
        },
        {
          name: 'Invoice Fulfillment',
          menuName: 'Drivex Accounts',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/uvdreports/reports/fullfillment-sales',
          width:''
        },
        {
          name: 'Drivex Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/drivex-purchase-order-list',
          width:''
        },
        {
          name: 'Drivex Inward Invoice',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/drivex-invoice-list',
          width:''
        },
        {
          name: 'Drivex GRN',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/drivex-grn-list/drivex-grn-list',
          width:''
        },
        {
          name: 'Drivex Outward Invoice',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/drivex-outlet-invoice-list',
          width:''
        },
        {
          name: 'Drivex Sale Order',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/drivex-sale-order/drivex-sale-order-list',
          width:''
        },
        // {
        //   name: 'Drivex Sale Order',
        //   isCustomize: false,
        //   menuName: 'Drivex Accounts',
        //   icon: 'assets/icons/menu_refurbishment.png',
        //   state: '/parts/purchase-order-list/drivex-sale-order-list',
        //   width:''
        // },
        {
          name: 'Drivex Delivery Advice',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/delivery-order-list',
          width:''
        },
        {
          name: 'Drivex Pick List',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/pick-statement-list',
          width:''
        },
        {
          name: 'Drivex Pack List',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/pack-statement-list',
          width:''
        },
        {
          name: 'Drivex Invoice',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/invoice-order-list',
          width:''
        },
        {
          name: 'Warehouse Purchase Order',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list/warehouse-purchase-order-list',
          width:''
        },
        {
          name: 'Vendor Invoice Info',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/direct-invoice-list/wh-invoice-list',
          width:''
        },
        {
          name: 'Warehouse GRN',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/wh-grn-list/wh-grn-list',
          width:''
        },
        {
          name: 'Warehouse GRN',
          isCustomize: false,
          menuName: 'Drivex Accounts',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/wh-grn-list/wh-grn-list',
          width:''
        },
        {
          name: 'Purchase Order',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/purchase-order-list',
          width:''
        },
        {
          name: 'Purchase Order Payment Record',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/po-payment-record/po-payment-record-list',
          width:''
        },
        {
          name: 'Physical Inventory Check',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-job-card.png',
          state: '/parts/physical-inventory-check-list',
          width:''
        },
        {
          name: 'Part Stock Transfer',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/parts/part-stock-transfer-list',
          width:''
        },
        {
          name: 'Part Stock Receipt',
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/parts/part-stock-receipt-list',
          width:'' 
        },
        {
          name: 'Spare Quotation',         
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/parts/sale-order/spare-quotation-list',
          width:''
        },
        {
          name: 'DRIVEX DC',         
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/parts/delivery-chalan-list',
          width:''
        },
        {
          name: 'DRIVEX DC GRN',         
          isCustomize: false,
          menuName: 'UVD PARTS',
          icon: 'assets/icons/sub-job-card.png',
          state: '/parts/grn/delivery-chalan-grn-list',
          width:''
        },
        {
          name: 'Insurance',
          menuName: 'UVD SALES',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-sales/uvd-insurance',
          width:''
        },
        {
          name: 'Call Log',
          menuName: 'Call Log',        
          icon: 'assets/icons/menu_support.png',
          state: 'https://tradewithtvs.com/TVSCARE/NewTvsCareReport/Default.aspx',
          // state: 'calllog/call-log-site',
          isActive: false,
        
        },
        {
          name: 'Support CallLog',
          menuName: 'Call Log',        
          icon: 'assets/icons/menu_support.png',
          state: '/call-log/support-call',
          isActive: false,
        
        },
        {
          name: 'Vehicle Profit and Loss',
          menuName: 'SALES',        
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: 'uvdreports/reports/profit-and-loss',
          isActive: false,
        },
        {
          name: 'HSN Percentage Details',
          menuName: 'COMMON',        
          icon: 'assets/icons/sub-outwork-memo.png',
          state: 'uvdreports/reports/hsn',
          isActive: false,
        },
        {
          name: 'V101-Enquiry and Followup Details',
          menuName: 'SALES',        
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: 'uvdreports/reports/enquiry-and-followup',
          isActive: false,
        },
        {
          name: 'S401-Monthly Service Report',
          menuName: 'SALES',        
          icon: 'assets/icons/sub-asc-warrenty-claim.png',
          state: 'uvdreports/reports/monthly-service-report',
          isActive: false,
        },
        {
          name: 'S408-Service potential Report',
          menuName: 'SERVICE',        
          icon: 'assets/icons/sub-service-reminder.png',
          state: 'uvdreports/reports/service-potential-revenue-loss',
          isActive: false,
        },
        {
          name: 'S604-Service Inflow Vs Delivered Details',
          menuName: 'SERVICE',        
          icon: 'assets/icons/sub-service-reminder.png',
          state: 'uvdreports/reports/service-inflow',
          isActive: false,
        },
        {
          name: 'S605-Jobcard Information Report',
          menuName: 'SERVICE',        
          icon: 'assets/icons/sub-service-reminder.png',
          state: 'uvdreports/reports/jobcard-information',
          isActive: false,
        },
        {
          name: 'P201-Parts current stock',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/part-Current-Stock',
          isActive: false,
        },
        {
          name: 'P202-Parts Stock Value',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/part-stock-value',
          isActive: false,
        },
        {
          name: 'P204-Parts Item Ledger',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/parts-item-ledger',
          isActive: false,
        },
        {
          name: 'P205-Parts Stock Transfer',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvdreports/reports/part-stock-transfer',
          isActive: false,
        },
        {
          name: 'P207-Parts Non Moving Stock',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/non-moving-stock',
          isActive: false,
        },
        {
          name: 'P401-Parts Performance',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/part-performance',
          isActive: false,
        },
        {
          name: 'P402-Parts Monthly Average Sales',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/part-monthly-average-sales',
          isActive: false,
        },
        {
          name: 'P101-Parts Counter Invoice',
          menuName: 'PARTS',        
          icon: 'assets/icons/sub-job-card.png',
          state: 'uvdreports/reports/part-counter-invoice',
          isActive: false,
        },
        {
          name: 'Dashboard',
          menuName: 'REPORTS',        
          icon: 'assets/icons/menu_procurement.png',
          state: 'uvdreports/reports',
          isActive: false,
        },
          
          {
            name:'Vehicle Return',
            menuName:'SALES',
            isCustomize:false,
            icon:'assets/icons/sub-outwork-memo.png',
            state:'/sales/return-process/vehicle-return-list',
            width:''
          },
          // {
          //   name:"Sales GRN For AMD",
          //   menuName:'SALES',
          //   isCustomize:false,
          //   icon:'assets/icons/sub-outwork-memo.png',
          //   state:'/sales/sales-grn/sales-grn-amd-list',
          //   width:''
          // },
          //
          {
            name: 'Customer Master',
            menuName: 'MASTERS',
            isCustomize: false,
            icon: 'assets/icons/sub-outwork-memo.png',
            state: '/master/common/search-customer-master',
            width:''
          },
          {
            name: 'HALP Accessory GRN',
            menuName: 'Parts GRN Process',
            isCustomize: false,
            icon: 'assets/icons/menu_refurbishment.png',
            state: '/parts/grn/accessory-grn-halp',
            width:''
          }
          ,
          {
            name: 'Sales Grn Tvs',
            isCustomize: false,
            menuName: 'GRN PROCESS ',
            icon: 'assets/icons/menu_refurbishment.png',
            state: '/sales/sales-grn/sales-grn-tvs-list',
            width:''
          }
          //added by ankit anjan
          ,
          {
            name: 'Performa Invoice',
            isCustomize: false,
            menuName: 'SERVICE',
            icon: 'assets/icons/sub-job-card-invoice.png',
            state: '/service/performainvoice/performainvoice',
            width:''

          },
         

        {
          name: 'UVD Master',
          menuName: 'MASTERS',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/uvd-master',
          width:''
        },
        {
          name: 'UVD Master',
          menuName: 'MASTERS',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/uvd-master',
          width:''
        },
        {
          name: 'Part Master',
          menuName: 'SPARES',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/master/spares/part-master-list',
          width:''
        },
        {
          name: 'Sparepart Master',
          menuName: 'SPARES',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-payment.png',
          state: '/master/spares/part-master-list',
          width:''
        },          
        {
          name: 'Part Price',
          menuName: 'SPARES',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/master/spares/part-price-master-list',
          width:''
        },
        {
          name: 'Part Cost',
          menuName: 'SPARES',
          isCustomize: false,
          icon: 'assets/icons/sub-service-appoinment.png',
          state: '/master/spares/part-cost-master-list',
          width:''
        },
        {
          name: 'Vendor Master',
          menuName: 'Spares',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/master/common/vendor-master-list',
          width:''
        },
        {
          name: 'Employe Master',
          menuName: 'Spares',
          isCustomize: false,
          icon: 'assets/icons/menu_procurement.png',
          state: '/master/common/employee-master-list',
          width:'wpx-20'
        },
        {
          name: 'Labour Master',
          menuName: 'Service',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/service/labour-master-list',
          width:'wpx-20'
          },         
        {
          name: 'Labour Price',
          menuName: 'Service',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/service/labour-price-list',
          width:'wpx-20'
        },
        {
          name: 'Service Appointments',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-service/uvd-service-appointment',
        },
        {
          name: 'Job Card',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-service/uvd-job-card',
        },
        {
          name: 'Job Card Invoice',
          menuName: 'UVD SERVICE',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-service/uvd-job-card-invoice-list',
        },
        {
          name: 'UVD FollowUp',
          menuName: 'UVD FOLLOWUP',
          isCustomize: false,
          icon: 'assets/icons/sub-job-card.png',
          state: '/uvd/uvd-followup-list',
        },
        {
          name: 'Employee Master',
          menuName: 'MASTERS',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/common/search-employee-master',
          width:''
        },
        {
          name: 'Vehicle Master',
          menuName: 'SALES',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/common/search-vehicle-master',
          width:''
        },
        //Code Changed By Anil on 29-04-2021
        {
            name:'Sales Other Vendor GRN',
            menuName:'SALES',
            isCustomize:false,
            icon:'assets/icons/sub-outwork-memo.png',
            state:'/sales/return-process/vehicle-return-list',
            width:''

        },
        {
          name:'Vehicle Return',
          menuName:'SALES',
          isCustomize:false,
          icon:'assets/icons/sub-outwork-memo.png',
          state:'/sales/return-process/vehicle-return-list',
          width:''
        },
        // {
        //   name:"Sales GRN For AMD",
        //   menuName:'SALES',
        //   isCustomize:false,
        //   icon:'assets/icons/sub-outwork-memo.png',
        //   state:'/sales/sales-grn/sales-grn-amd-list',
        //   width:''
        // },
        //
        {
          name: 'Customer Master',
          menuName: 'MASTERS',
          isCustomize: false,
          icon: 'assets/icons/sub-outwork-memo.png',
          state: '/master/common/search-customer-master',
          width:''
        },          
        
        {
          name: 'HALP Accessory GRN',
          menuName: 'Parts GRN Process',
          isCustomize: false,
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/parts/grn/accessory-grn-halp',
          width:''
        }
        ,
        {
          name: 'Sales Grn Tvs',
          isCustomize: false,
          menuName: 'GRN PROCESS ',
          icon: 'assets/icons/menu_refurbishment.png',
          state: '/sales/sales-grn/sales-grn-tvs-list',
          width:''
        }
       

  ],
  // uvdMenu:[
  //     {
  //         name: 'Procurement Vehicle',
  //         menuName: 'PROCUREMENT',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/procurement',
  //       },
  //       {
  //         name: 'Refurbishment Jobcard',
  //         menuName: 'REFURBISHMENT',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/refurbishment',
  //       },
  //       {
  //         name: 'Vehicle Valuation',
  //         menuName: 'REFURBISHMENT',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/refurbishment/vehicle-valuation',
  //       },
  //       {
  //         name: 'Sales Enquiry',
  //         menuName: 'UVD SALES',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/uvd-sales',
  //       },
  //       {
  //         name: 'Sales FollwUp',
  //         menuName: 'UVD SALES',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/uvd-sales/sales-followup',
  //       },
  //       {
  //         name: 'Job Card',
  //         menuName: 'UVD SERVICE',
  //         isCustomize: false,
  //         icon: 'assets/icons/uvd.png',
  //         state: '/uvd/uvd-service/uvd/job-card',
  //       },
  //       {
  //         name: 'Direct Invoice',
  //         menuName: 'UVD PART',
  //         isCustomize: false,
  //         icon: 'assets/icons/sub-job-card-invoice.png',
  //         state: '/uvd/uvd-part/direct-invoice',
  //       },
  //       {
  //         name: 'GRN Others',
  //         menuName: 'UVD PART',
  //         isCustomize: false,
  //         icon: 'assets/icons/sub-transit-claim.png',
  //         state: '/uvd/uvd-part/grn-others',
  //       },
  //       {
  //         name: 'GRN APS',
  //         menuName: 'UVD PART',
  //         isCustomize: false,
  //         icon: 'assets/icons/menu_refurbishment.png',
  //         state: '/uvd/uvd-part/grn-aps',
  //       }
  // ]
  abc: [
      {
          name: 'A',
          value: 1
      },
      {
          name: 'B',
          value: 2
      },
      {
          name: 'C',
          value: 3
      },       
  ],
  ved: [
      {
          name: 'V',
          value: 1
      },
      {
          name: 'E',
          value: 2
      },
      {
          name: 'D',
          value: 3
      }, 
      {
          name: 'None',
          value: 0
      },      
  ],
  fsn: [
      {
          name: 'F',
          value: 1
      },
      {
          name: 'S',
          value: 2
      },
      {
          name: 'N',
          value: 3
      }, 
      {
          name: 'None',
          value: 0
      },      
  ],
  kittype: [
      {
          name: 'TVS',
          value: 1
      },
      {
          name: 'Dealer',
          value: 2
      },
      {
          name: 'Suggestive',
          value: 3
      },              
  ],
  HPRejectReason: [
      {
          name: 'Risky_Customer',
          value: 1
      },
      {
          name: 'Risky_Area',
          value: 2
      },
      {
          name: 'Invalid_Document',
          value: 3
      },
      {
          name: 'Not_to_be_Given',
          value: 4
      } ,
      {
          name: 'No_Gurantor',
          value: 5
      }          
  ],
  HPDocumentType: [
      {
          name: 'Passport',
          value: 1
      },
      {
          name: 'Address Proof',
          value: 2
      },
      {
          name: 'Salary Slip',
          value: 3
      },
      {
          name: 'Voters ID',
          value: 4
      } ,
      {
          name: 'Aadhar Card',
          value: 5
      }          
  ]
}

export enum VoucherType
{
  Booking_Advance_Refund = 201,
  Vehicle_Sales_Return_Payment = 204,
  Spares_Sales_Return_Payment = 206,
  Spares_GRN_Payment = 212,
  Miscellaneous_Payment = 217,
  Booking_Advance = 101,
  JobCardAdvance = 102,
  JobcardRefund = 203,
  Vehicle_Sale_Invoice_Receipt = 104,
  Vehicle_Registration_Invoice_Receipt = 105,
  Vehicle_Insurance_Invoice_Receipt = 106,
  Vehicle_Accessories_Invoice_Receipt = 107,
  Spares_Sale_Invoice_Receipt = 113,
  Service_Spares_Invoice_Receipt = 111,
  Miscellaneous_Receipt = 119,
  Booking_General_Refund = 202,
  Self_Managed_HP = 802,
  AMCReceipt = 103,
  BrokerSaleReceipt = 115,
  VendorAdvancePayment = 213,
  InsurancePayment = 214,
  HPRefund = 215,
  OnAccountPayment = 216,
  CommisionPayment = 209,
  VehicleCommissionPayment = 221,
  SparesCommissionPayment = 222,
  BookingDiscountPayment = 218,
  RegistrationPayment = 219,
  DiscountPayment = 220,
  OutworkPayment = 210,
  RegistrationReceipt = 120,
  ReceiptAdjustmentPayment = 208,
  ASC_Coupon_Claim_Payment = 223,
  ASCWarrantyClaimPayment = 224,
  OnAccountReceipt = 118,
  HPCompanyReceipt = 116,
  ASCWarrantyClaim = 402,
  DealerCouponClaim =501,
  DealerWarrantyClaim=502,
  DealerTransitClaim=503,
 Vehicle_Accessories_Return_Payment = 205,
  Vendor_Bill_Payment = 211,
  WelcomeKitReceipt = 121,
  ServiceRequestAdvance = 123

}

export enum DocumentType
{
  Receipt = 1,
  Payment = 2,
  CreditNote = 3,
  DebitNote = 4,
  Journal = 5,
  Booking = 6,
  JobCard = 7,
  VehicleInvoice = 8,
  VehicleRegistrationInvoice = 9,
  VehicleAccessoryInvoice = 10,
  VehicleInsuranceInvoice = 11,
  SparesSaleInvoice = 12,
  SparesAccessoriesInvoice = 13,
  ServiceLabourInvoice = 14,
  ServiceOutworkInvoice = 15,
  ServiceWaterwashInvoice = 16,
  ServiceSparesInvoice = 17,
  ServiceAccessoriesInvoice = 18,
  AMC = 19,
  OutworkPayment = 20,
  CommissionPayment = 21,
  BrokerSale = 22,
  ASCCouponClaim = 23,
  ASCWarrantyClaim = 24,
  ASCTransitClaim = 25,
  DealerCouponClaim = 26,
  DealerWarrantyClaim = 27,
  DealerTransitClaim = 28,
  VendorBillPayment = 29,
  SparesGRN = 30,
  VehicleSalesReturn = 31,
  VehicleAccessoryReturn = 32,
  SparesSalesReturn = 33,
  SparesAccessoryReturn = 34,
  ShortageDamageClaim = 35,
  ExcessClaim = 36,
  SpecialReceipt = 37,
  SpecialPayment = 38,
  SpecialJournal = 39,
  InsurancePayment = 40,
  RegistrationTracking = 41,
  MisReceipts = 42,
  Quotation = 43,
  VehicleCommissionPayment = 44,
  SparesCommissionPayment = 45,
  SparesPurchaseReturn = 46, //required for rack-bin display based on document type
  VehiclePurchaseReturn = 47,
  CRMWelcomeKit = 48
}
export enum PartyCategory
  {
      Customer = 'Customer',
      Product = 'Product',
      Vendor = 'Vendor',
      Bank = 'Bank',
      HPCompany = 'HPCompany',
      InsuranceCompany = 'InsuranceCompany',
      CreditCardType = 'CreditCardType',
      Schemes = 'Schemes',
      Discounts = 'Discounts',
      Taxes = 'Taxes'
  }
